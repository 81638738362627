import React from "react"
import Layout from "../components/LayoutComponent"
import Seo from "../components/SeoComponent"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import "../assets/recomend.css"
const Recomendations = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "preporuka" })}
        canonicalUrl={"recomendations"}
      />
      <div className="recomend-container">
        <section className="recomend-txt">
          <h1>
            <FormattedMessage id="preporuke.tekst" />
          </h1>
        </section>
        <section className="recomend-img-container">
          <Link to="/recomendation-uefa-2020/" className="recomend-img-link">
            <StaticImage
              src="../images/recomend/UEFA_EURO_2020.jpg"
              alt="Uefa Euro 2020 Logo"
              layout="constrained"
              placeholder="blurred"
            />
          </Link>
          <Link to="/recomendation-multilingua/" className="recomend-img-link">
            <StaticImage
              src="../images/recomend/MULTILINQUA.jpg"
              alt="Multilinqua Logo"
              layout="constrained"
              placeholder="blurred"
            />
          </Link>
          <Link to="/recomend-larq/" className="recomend-img-link">
            <StaticImage
              src="../images/recomend/LARQ.jpg"
              alt="Larq Logo"
              layout="constrained"
              placeholder="blurred"
            />
          </Link>
          <Link to="/recomend-iolar/" className="recomend-img-link">
            <StaticImage
              src="../images/recomend/IOLAR.jpg"
              alt="Iolar Logo"
              layout="constrained"
              placeholder="blurred"
            />
          </Link>
          <Link to="/recomend-234/" className="recomend-img-link">
            <StaticImage
              src="../images/recomend/234.jpg"
              alt="234 Logo"
              layout="constrained"
              placeholder="blurred"
            />
          </Link>
          <Link to="/recomend-mp-enterprise/" className="recomend-img-link">
            <StaticImage
              src="../images/recomend/MP_ENTERPRISE.jpg"
              alt="MP Enterprise Logo"
              layout="constrained"
              placeholder="blurred"
            />
          </Link>
        </section>
      </div>
    </Layout>
  )
}

export default Recomendations
